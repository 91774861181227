export const MP_COVER_TITLE = 'Madpaws accident cover';
export const MP_COVER_DESCRIPTION =
  'Pet services paid through the Madpaws website and app are protected by injury and illness cover.';
export const MP_COVER_LINK =
  'https://madpaws.brainfi.sh/articles/mad-paws-accident-cover-what-is-included-UZbCSbI7xa';
export const MP_COVER_LEARN_MORE = 'Learn about accident cover';

export const CANCELLATION_POLICY_TITLE = 'Cancellation policy';
export const CANCELLATION_POLICY_LINK =
  'https://madpaws.brainfi.sh/articles/what-is-the-mad-paws-cancellation-and-refund-policy-0dL1b7QXvR';
export const CANCELLATION_POLICY_LEARN_MORE = 'Learn about cancellation';
