import {
  Heading,
  IconCheck,
  IconClose,
  useLargeFromMediaQuery,
  useMediumFromMediaQuery,
} from '@madpaws/design-system';
import getConfig from 'next/config';
import React from 'react';

import { ImageWithFallback } from '~/common/components/ImageWithFallback/ImageWithFallback';
import {
  FEATURE_HOLD_OUT_SPLIT_ID,
  FEATURE_HOLD_OUT_SPLIT_ID_DYNAMIC_CONFIG,
  FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES,
} from '~/components/SplitIO/constants';
import { useSplitIO } from '~/components/SplitIO/useSplitIO';

import styles from './Kennels.module.css';

import type { ReactElement } from 'react';

// const { publicRuntimeConfig } = getConfig();

const { publicRuntimeConfig } = getConfig();
/*
 * This HTML ID-s are used to find the elements
 * and this is required to inject CSS from ReactNative app WebView
 * TODO(WEB-2258): Rename this variable to a more meaningful name
 */
const KENNELS_HTML_ID = 'Explore_more_product_services';

const KENNELS = [
  'Carers look after many dogs',
  'Pets spend most of the day in a cage',
  'Stressful environments with lots barking',
  'Routine disruption',
  'Kennel cough',
];

const MADPAWS = [
  'Personal care from a sitter',
  'Pets stay in sitters home or your home',
  'Pets have space to roam',
  'Pets routine is maintained',
];

export const Kennels = (): ReactElement | null => {
  const { getDynamicConfigEnabled } = useSplitIO();

  const isMediumFromViewport = useMediumFromMediaQuery();
  const isLargeFromViewport = useLargeFromMediaQuery();

  const isHomePageComponentChangesEnable = getDynamicConfigEnabled(
    FEATURE_HOLD_OUT_SPLIT_ID,
    FEATURE_HOLD_OUT_SPLIT_ID_ID_VALUES.ON,
    FEATURE_HOLD_OUT_SPLIT_ID_DYNAMIC_CONFIG.HOME_PAGE_COMPONENT_CHANGES
  );

  if (!isHomePageComponentChangesEnable) {
    return null;
  }

  return (
    <section className={styles.root} id={KENNELS_HTML_ID}>
      <ImageWithFallback
        alt="Picture of a trusted pet sitter"
        fallback={`${publicRuntimeConfig.staticPath}/images/homepage/kennels/${
          isMediumFromViewport ? 'kennels.webp' : 'kennelsMobile.png'
        }`}
        loading="lazy"
        webpSrc={`${publicRuntimeConfig.staticPath}/images/homepage/kennels/${
          isMediumFromViewport ? 'kennels.webp' : 'kennelsMobile.png'
        }`}
      />
      <div className={styles.rootContainer}>
        <Heading
          fontFamily="heading"
          size={isLargeFromViewport ? 'large1x' : isMediumFromViewport ? 'medium' : 'small1x'}
        >
          An alternative to kennels
        </Heading>
        <div className={styles.content}>
          <div className={`${styles.details} ${styles.kennels}`}>
            <p className={styles.title}>Kennels</p>

            {KENNELS.map((item, index) => (
              <div className={styles.detailRaw} key={`${index}_kennels`}>
                <IconClose />
                <p>{item}</p>
              </div>
            ))}
          </div>

          <div className={`${styles.details} ${styles.madpaws}`}>
            <p className={styles.title}>Mad Paws</p>

            {MADPAWS.map((item, index) => (
              <div className={styles.detailRaw} key={`${index}_madpaws`}>
                <IconCheck />
                <p>{item}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};
